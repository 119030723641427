import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {catchError, EMPTY, Observable, Subject, throwError} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {LoadingService, LoadingStates} from './loading.service';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HTTPService {
  ServerURL = `${environment.apiUrl}/v1`;
  ServerURLAuth = `${environment.backendAuthURL}/v1`;


  IsLoginedChange$ = new Subject<boolean>();
  IsLogined = false;

  UpdateStyle$ = new Subject<any>();
  ScrollToBottom$ = new Subject<any>();

  IsLoadingChange$ = new Subject<boolean>();


  IsUserChange$ = new Subject<any>();
  User: any = {
    id: null,
    phone: "",
    first_name: "",
    last_name: "",
    createdAt: "",
    profileType: "",
    active: true,
    banned: false,
    needReports: false,
    ownerId: null,
    companyId: null
  };


  headers = new HttpHeaders({
    "Access-Control-Allow-Origin": "*",
  });

  // LoadingSubject: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    public loadingService: LoadingService,
  ) {
    this.IsLoginedChange$.next(false);
    this.IsLoginedChange$.subscribe((val) => {
      let prevIsLogined = this.IsLogined;
      this.IsLogined = val;
      if (this.IsLogined && !prevIsLogined) {
        this.router.navigate(["/", "base"]);
      } else if (
        this.IsLogined &&
        (this.isInsideAuthModule())
      ) {
        this.router.navigate(["/", "base"]);
      }
      if (!this.IsLogined) {
        this.router.navigate(["auth", "login"]);
      }
    });
  }

  CheckToken() {
    if (localStorage.getItem("token")) {
      this.IsLoadingChange$.next(true);
      if (localStorage.getItem("token")) {
        this.BaseInitByToken(localStorage.getItem("token"));
        this.IsLogined = localStorage.getItem("token") ? true : false;
      }

      this.GetMe().subscribe(
        (res) => {
          this.User = res;
          // this.isLoading = false;
          this.IsUserChange$.next(true);
          this.IsLoginedChange$.next(true);
          this.IsLoadingChange$.next(false);


        },
        (err) => {
          this.ClearSession();
        }
      );
    } else {
      // this.isLoading = false;
      this.ClearSession();
    }
  }

  BaseInitByToken(data: any) {
    if (data) {
      if (!this.headers.has("Authorization")) {
        this.headers = this.headers.append("Authorization", data);
      } else {
        this.headers = this.headers.set("Authorization", data);
      }
    }
  }

  private isInsideAuthModule() {
    return window.location.href.includes("auth") ? true : false;
  }


  Login(payload: any) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURLAuth + "/admin/user/auth", payload);
    }, this.loadingService.StatesNames.LOGIN)
  }

  ClearSession() {
    localStorage.setItem("token", "");
    localStorage.removeItem("token");
    this.headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
    });
    if (!this.isInsideAuthModule()) {
      this.router.navigate(["auth", "login"]);
    }
  }


  GetMe(State: any = this.loadingService.StatesNames.GLOBAL) {
    // return this.SendGet("/admin/user/me", State).pipe();
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURLAuth + "/admin/user/me", {headers: this.headers});
    }, State);
  }


  RequestHandler(
    runRequest: () => Observable<any>,
    RequestType = LoadingStates.UNKNOWN
  ): Observable<any> {
    let loadingId = this.loadingService.addLoading(RequestType);
    return runRequest().pipe(
      debounceTime(50),
      tap(
        (dataRes) => {
          this.loadingService.removeLoading(loadingId);
        },
        (catchError) => {
          this.loadingService.removeLoading(loadingId);
        }
      ),
      catchError(err => {
        if (err.status === 401) {
          this.ClearSession()
        }
        return throwError(err);

      })
    );
  }

  SendPost(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.post(this.ServerURL + method, data, {
        headers: this.headers,
      });
    }, type);
  }


  SendPut(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.put(this.ServerURLAuth + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  SendGet(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURLAuth + method, {headers: this.headers});
    }, type);
  }

  SendGetApi(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.get(this.ServerURL + method, {headers: this.headers});
    }, type);
  }

  SendGetText(method: string, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http
        .get(this.ServerURL + method, {
          headers: this.headers,
          responseType: "text",
        })
        .pipe(
          tap((data) => {
            //console.log(data, typeof(data))
          })
        );
    }, type);
  }

  SendDelete(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.delete(this.ServerURL + method, {
        headers: this.headers,
      });
    }, type);
  }

  SendPatch(method: string, data: any, type = LoadingStates.UNKNOWN) {
    return this.RequestHandler(() => {
      return this.http.patch(this.ServerURLAuth + method, data, {
        headers: this.headers,
      });
    }, type);
  }

  Logout() {
    return this.RequestHandler(() => {
      return this.http
        .post(this.ServerURLAuth + "/logout", {}, {headers: this.headers})
    }, this.loadingService.StatesNames.LOGOUT);
  }

}
